import * as React from "react";
import { useState, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faL } from '@fortawesome/free-solid-svg-icons';
import '@fontsource/poppins';
import addressBook from "../../images/Acquisition/address_book_notebook_icon.svg";
import contactIcon from "../../images/Acquisition/contact_icon.svg";
import UserService from "../../services/UserService";
import ShowPaytmPaymentPage from "../ShowPaytmPaymentPage";
import ShowRazorPayPaymentPage from "../ShowRazorPayPaymentPage";
import OTPValidation from '../../subComponents/OTPValidation';
import CorporateMailUpdatePopup from '../../subComponents/CorporateMailUpdatePopup';
import CorporateDigitalPopup from '../../subComponents/CorporateDigitalPopup';
import { moengageCheckoutBtn_Clicked,moengageCheckoutPage_Viewed,moengage_Acq_CheckoutBackBtn_Clicked } from "../../common/moengageFunction";
import TermsPopup from "../../subComponents/TermsPopup";
import PageLoader from '../../subComponents/PageLoader';
import {registrationGetCityNameOrder } from "../../common/commonFuntion";
import ReactGA from 'react-ga';
import { ToastContainer, toast } from 'react-toastify';
import {planNames} from '../../utils/utils.js';

const schemPublication = sessionStorage.getItem("stg_selectedPlan_Name") || null;

function Order({ orderData }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [paytmPageShow, setPaytmPageShow] = useState(false);
  const [gateway, setGateway] = useState("Razorpay");
  const [transToken, setTransToken] = useState("");
  const [mid, setMid] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [termsErrmsg, setTermsErrmsg] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [mobileError, setMobileError] = useState();
  const [emailError, setEmailError] = useState();
  const [showPopupAlertState, setShowPopupAlertState] = useState(false);
  const [pincodeGiven, setPincodeGiven] = useState(false);
  const [utmSource, setUtmSource] = useState();
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showMailUpdateModal, setShowMailUpdateModal] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpValidatedEmail, setOtpValidatedEmail] = useState(false);
  const [currentMobile, setCurrentMobile] = useState(null);
  const [otpType, setOtpType] = useState(false);
  const [forInstitutional, setForInstitutional] = useState(false);
  const [schemPublication, setSchemPublication] = useState(sessionStorage.getItem("stg_selectedPlan_Name"));
  const [validDigitalOrder, setValidDigitalOrder] = useState(false);

  const baseUrl = window.location.origin;
  const CORPORATE_STATUS = process.env.REACT_APP_CORPORATE_STATUS === 'true';

  const digitalPublication = ["LM", "HD", "LM;Mint", "Mint;LM", "BP;LM", "LM;BP"];

  const defultAlert = (msg) => toast.warn(msg, {
    position: toast.POSITION.TOP_CENTER
  });

  const notFound = () => toast.warn("Some network issues, Please try again", { position: toast.POSITION.TOP_CENTER });
  const emailUpdated = () => toast.success("Email has been updated", { position: toast.POSITION.TOP_CENTER });
  const emailIdNotValid = () => toast.warn("Please enter a valid email", { position: toast.POSITION.TOP_CENTER });
  const networkIssue = () => toast.warn("Some network issue, Please try again!", { position: toast.POSITION.TOP_CENTER });
  const enterEmail = () => toast.warn("Please enter your email Id", { position: toast.POSITION.TOP_CENTER });
  

  useEffect(() => {
    const updatedData = { ...data };
    const urlParams = new URLSearchParams(window.location.search);
    const utmSourceParam = urlParams.get("utm_source")?.toUpperCase();
    const validSources = process.env.REACT_APP_VALID_SOURCES?.split(",").map(source => source.trim().toUpperCase()) || [];
    if (
      utmSourceParam && validSources.includes(utmSourceParam.toUpperCase())
    ) {
        setUtmSource(utmSourceParam);
    }

    updatedData.city = registrationGetCityNameOrder(orderData?.cityName ?? sessionStorage.getItem("stg_city"));
    updatedData.pincode = orderData?.cityPincode ?? sessionStorage.getItem("moe_acq_Pincode");
    updatedData.mobile_no = data.mobile_no || sessionStorage.getItem("stg_mobile_number") || "";
    const otpValidatedCheckVar = sessionStorage.getItem("stg_otpValidated") === "true";
    const otpValidatedCheck = otpValidated || otpValidatedCheckVar || false;
    const schemPublicationData = schemPublication || sessionStorage.getItem("stg_selectedPlan_Name") || null;
    const otpEmailValidatedCheckVar = sessionStorage.getItem("stg_otpEmailValidated") === "true";
    const otpEmailValidatedCheck = otpValidatedEmail || otpEmailValidatedCheckVar || false;

    const forInstitutionalVar = utmSourceParam?.toUpperCase() === "CORPORATE" && CORPORATE_STATUS;
    setData(updatedData);
    setOtpValidated(otpValidatedCheck);
    setForInstitutional(forInstitutionalVar);
    setSchemPublication(schemPublicationData);
    // moengageCheckoutPage_Viewed();
    ReactGA.ga('send', 'pageview', '/AcquisitionShippingAddress');

    if (orderData?.cityPincode !== "") {
        setPincodeGiven(true);
    }

    if(forInstitutionalVar){
      updatedData.email = data.email || sessionStorage.getItem("stg_verifiedEmail") || "";
      setOtpValidatedEmail(otpEmailValidatedCheck);
      if (!updatedData.email && !otpEmailValidatedCheck) {
        window.location = `/`;
      }
      // const validOrderOnDigitalStatus = await validOrderOnDigitalFn(schemPublicationData, updatedData.mobile_no, updatedData.email);
      // console.log(" validOrderOnDigitalStatus 333",validOrderOnDigitalStatus);

      // if(!validOrderOnDigitalStatus){
      //   return;
      // }
    }
    
    if (updatedData.mobile_no && otpValidatedCheck) {
        UserService.addNewLeadGenerate(
            updatedData.mobile_no,
            sessionStorage.getItem("stg_scheme_id"),
            utmSourceParam,
            forInstitutionalVar
        )
            .then((response) => {
                if (response.data.success) {
                    const leadId = response.data.lead_id;
                    sessionStorage.setItem("lead_id", leadId);
                }
            })
            .catch((err) => {
                console.error("Error in addNewLeadGenerate:", err);
            });
    } else if(updatedData.mobile_no){
        
        console.log("stg_mobile_number2 === ", sessionStorage.getItem("stg_mobile_number"));
        // console.log("ddsadas = ", data);
        checkMobileExist(updatedData.mobile_no, otpEmailValidatedCheck, forInstitutionalVar);
    }else{
      console.log("stg_mobile_number === ", sessionStorage.getItem("stg_mobile_number"));
    }
}, []); // Dependency array includes mobile_no

  const validOrderOnDigitalFn = async (schemPublicationData, mobile, email) => {

    if (digitalPublication.includes(schemPublicationData)) {
      try {
        console.log("data = ", email, " /  ", mobile);

        if(email && mobile){
          const response = await UserService.validOrderOnDigital(email, mobile);
          console.log(response, " jjkkjkj333 ", response.data.message, " status = ", response.data.success);
        
          if (response?.data?.success) {
              setValidDigitalOrder(false); // Valid order, no action needed
              return true;
          } else if (response?.data?.message === "Subscription already exists!") {
            console.log(" jjkkjkj ");
            setValidDigitalOrder(true);
            // setShowMailUpdateModal(true);
            return false;
          }else {
               // Invalid order, show modal
              // defultAlert(`${response?.message || "Something went wrong. Please try again."}`);
              setValidDigitalOrder(false);
              return true;
          }
        }else{
          setValidDigitalOrder(false); 
          return true;
        }
      } catch (err) {
          console.error("Error in validating digital order:", err);
          setValidDigitalOrder(false); 
          return true;
      }
    }else{
      setValidDigitalOrder(false); 
      return true;
    }

  };

  const validateOtp = (otp) => {
    return new Promise((resolve) => {
        // const otpSource = otpType ? data.email : data.mobile_no;
        const otpSource = data.mobile_no;
        UserService.verifiyOtpFresh(otp, otpSource, "SSP")
            .then((response) => {
                if (response && response.data) {
                    resolve({ success: true });
                } else if (!response.success) {
                    resolve({
                        success: false,
                        message: `${response.message || "Invalid OTP. Please try again."}`,
                    });
                } else {
                    resolve({
                        success: false,
                        message: "Something went wrong. Please try again.",
                    });
                }
            })
            .catch((err) => {
                console.error("Error in validateOtp:", err);
                if (err && err.response && err.response.data) {
                    if (err.response.status === 429) {
                        resolve({
                            success: false,
                            message: "You have entered incorrect OTP multiple times. Please try after 30 minutes.",
                        });
                    } else {
                        resolve({
                            success: false,
                            message: `${err.response.data.message || "Something went wrong!"}`,
                        });
                    }
                } else {
                    resolve({
                        success: false,
                        message: "Something went wrong!",
                    });
                }
            });
    });
  };


  const createNewLead = async () => {
    try {
        // if (utmSource === "mygate" && otpResponse?.data) {
        //     renderMygateData(otpResponse.data);
        //     const leadId = otpResponse.data.id;
        //     sessionStorage.setItem("lead_id", leadId);
        //     saveFieldDataonBlurEvent();
        // } else {
      const schemeId = sessionStorage.getItem("stg_scheme_id");
      const storedMobile = sessionStorage.getItem("stg_mobile_number");
      // const forInstitutional = utmSource?.toUpperCase() === "CORPORATE" && CORPORATE_STATUS;

      if (!storedMobile || storedMobile !== currentMobile || data.mobile_no) {
          sessionStorage.setItem("stg_mobile_number", data.mobile_no);

          const leadResponse = await UserService.addNewLeadGenerate(data.mobile_no, schemeId, utmSource, forInstitutional);

          if (leadResponse?.data?.success) {
              const leadId = leadResponse.data.lead_id;
              sessionStorage.setItem("lead_id", leadId);
          }
      } else {
        setMobileError("Mobile number is required!");
      }
    } catch (error) {
      setMobileError(error);
    }
  }

  const sendOtp = async () => {
    
    let result = false;
    // if (otpType) {
    //     result = saveEmail(data.email);
    // } else {
        result = await checkMobileExist(data.mobile_no, otpValidatedEmail, forInstitutional);
    // }
    // console.log(`Sending OTP to, result = ${result}, type = ${otpType}`);
    return { success: result };
  };


  const handleChange = (e) => {
    e.persist();
    let { name , value } = e.target; 
    const re = /^[a-zA-Z\s]*$/g;
    if (name=='first_name' && (value === "" || re.test(value))) {      
      setData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }else if(name=='pincode'){
      let pincodevalue = e.target.value.replace(/\D/g, "");
      setData((prevState) => ({ ...prevState, [e.target.name]: pincodevalue }));
    }else if(name=='mobile_no'){
      setOtpValidated(false);
      setOtpType(false);
      // console.log("OOOOOOOOOOOOOOOO");
      let mobileVal = e.target.value.replace(/\D/g, "");
      setData((prevState) => ({ ...prevState, [e.target.name]: mobileVal }));
    }
    // else if(name=='email'){
    //   setOtpType(true);
    //   setOtpValidatedEmail(false);
    //   setData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    // }
    else if(name!='first_name'){
      // setOtpValidatedEmail(false);
      setData((prevState) => ({ ...prevState, [name]: value }));
    }
    
  };

  const updateTermsCheckbox = (event) => {
    if (event.target.checked) {
      data.terms = true;
      setData({ ...data });
    } else {
      data.terms = false;
      setData({ ...data });
    }
    setIsChecked((current) => !current);
  };

  let genrateNumberUUId = () => {
    const length = 15;
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.round(Math.random() * (chars.length - 1))];

    sessionStorage.setItem("linkId", result);
    return result;
  };

  let cleanObj =(obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName]=='') {
        delete obj[propName];
      }
    }
    return obj
  }


  let saveFieldDataonBlurEvent = () =>{
    let savedataObj  = {
      "order_id"      : sessionStorage.getItem("lead_id"),
      "email"         : data.email,
      "customer_name" : data.first_name,
      "flat_no"       : data.flat,
      "block_or_street"  : data.block,
      "locality_non_master"  : data.locality,
      "city"          : data.city,
      "pin_code"      : data.pincode,
      "initiator"     : "acquisition_journey_info_update"} ; 

     let finalObj = cleanObj(savedataObj);

    UserService.addDeliveryAdress(finalObj).then((response) =>{
              
    })
  }

  const renderMygateData =(mygateData)=>{
    data.email = (mygateData.email) ? mygateData.email : '';
    data.first_name = ((mygateData.firstName) ? mygateData.firstName + ' ' : '') + ((mygateData.lastName) ? mygateData.lastName : '');
    data.flat = (mygateData.flatNo) ? mygateData.flatNo : '';
    data.block = (mygateData.floor) ? mygateData.floor : '';
    data.locality = (mygateData.otherSociety) ? mygateData.otherSociety : '';
    data.city = (mygateData.city) ? mygateData.city : '';
    data.pincode = (mygateData.pinCode) ? mygateData.pinCode : '';
    data.terms = true;
    setIsChecked(true);
//    console.log("isChecked == ", isChecked);
    setData({ ...data });

  }

  const updateEmailAddress = () => {
    return new Promise((resolve) => {
      const email = data.email;
      if (email && data.mobile_no) {
          UserService.updateEmail(email, data.mobile_no)
          .then((response) => {
              if (response && response.data) {
                  resolve({ success: true });
              } else if (!response.success) {
                  resolve({
                      success: false,
                      message: `${response.message || "Something went wrong. Please try again."}`,
                  });
              } else {
                  resolve({
                      success: false,
                      message: "Something went wrong. Please try again.",
                  });
              }
          })
          .catch((err) => {
              console.error("Error in updateEmailAddress-on fresh:", err);
              if (err && err.response && err.response.data) {
                  if (err.response.status === 429) {
                      resolve({
                          success: false,
                          message: "You hit multiple request at a times. Please try after 30 minutes.",
                      });
                  } else {
                      resolve({
                          success: false,
                          message: `${err.response.data.message || "Something went wrong!"}`,
                      });
                  }
              } else {
                  resolve({
                      success: false,
                      message: "Something went wrong!",
                  });
              }
          });
      }else{
        enterEmail();
      }
    });
  };

  const checkMobileExistWithEvent = async (e) => {
      e.preventDefault(); 
      setOtpType(false);
      setFormErrors({});
      setMobileError("");
      const enterMobile = e.target.value.trim();
      console.log("enterMobile == ", enterMobile);
      if (!enterMobile) {
          setFormErrors({ mobile_no: "This field is required." });
          return false;
      }

      if (enterMobile.length < 10) {
          setFormErrors({ mobile_no: "Mobile No. should not be less than 10 digits." });
          return false;
      }

      // if(forInstitutional){
      //   const validOrderOnDigitalStatus = await validOrderOnDigitalFn(schemPublication, enterMobile, data.email);
      //   if(!validOrderOnDigitalStatus){
      //     return;
      //   }
      // }

      if(otpValidated){
        return;
      }
      console.log("kjkjjk = ", enterMobile);
     await checkMobileExist(enterMobile, otpValidatedEmail, forInstitutional); 
  };

  const checkMobileExist = async (enterMobile, otpValidatedEmailVar, forInstitutionalVar) => {
    const trimmedMobile = enterMobile.trim();
    setCurrentMobile(trimmedMobile);
    setLoading(true);
  
    try {
      const otpResponse = await UserService.generateFreshMobOtp(trimmedMobile, "SSP");
      console.log("otpResponse == ", otpResponse);
      if (otpResponse?.success) {
        setShowOTPModal(true);
        setFormErrors({});
        setMobileError("");
        return true;
      }
  
      else if (!otpResponse?.success && otpResponse?.message === "Mobile number does Not exists") {
        console.log("otpValidatedEmailVar= ", otpValidatedEmailVar, "forInstitutionalVar = ", forInstitutionalVar, "email = ", data.email);
        if(otpValidatedEmailVar && forInstitutionalVar) {
          // alert("ok");
          // setValidDigitalOrder(false);
          setShowMailUpdateModal(true);
        }
        setMobileError(
          <>
            You are already an existing reader with us. Please{' '}
            <a href={baseUrl}>click here</a> to log in to your account.
          </>
        );
        return false;
      }
  
      else if (!otpResponse?.success && otpResponse?.message) {
        setMobileError(otpResponse.message);
        return false;
      }
      else{
        setMobileError("Something went wrong!");
        return false;
      }

    } catch (error) {
      console.error("Error in checkMobileExist:", error);
      setMobileError(error?.message || "An unexpected error occurred.");
      return false;
    } finally {
      setLoading(false);
    }
  };
  

  const saveEmailWithEvent = async (e) => {
      e.preventDefault();
      setOtpType(true);
      setFormErrors({});
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      let emailId = e.target.value;
      if (!emailId) {
        setFormErrors({email : "This field is required."});
        return false;
      } else if (emailId!= "" && !emailRegex.test(emailId)) {
        setFormErrors({email :"This is not a valid email format."});
        return false;
      }else{
        if (sessionStorage.getItem("lead_id")) {     
            saveFieldDataonBlurEvent();
        }
      }

      // if(otpValidatedEmail){
      //   return;
      // }
      // alert(e.target.value);
      // await saveEmail(e.target.value); 
  };

  const saveEmail = async (emailId) =>{
    setLoading(true);
    try {
      const otpResponse = await UserService.generateFreshEamilOtp(emailId, "SSP");

      if (otpResponse?.success) {
          setShowOTPModal(true);
          setFormErrors({});
          setEmailError("");
          setLoading(false);
          return true;

      }else if(otpResponse?.message){
        setEmailError(otpResponse.message);
        setLoading(false);
        return false;
      }
      else{
        setEmailError(`${otpResponse.message || "Something went wrong"}`);
        setLoading(false);
        return false;
      }

    } catch (error) {
      console.error("Error in checkMobileExistWithEvent:", error);
      setMobileError(error);
      setLoading(false);
      return false;
    }

  }

  const saveName = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let username = e.target.value;
    if (!username) {
      setFormErrors({first_name : "This field is required."});
    }else{
      if(sessionStorage.getItem("lead_id")!=undefined){       
        saveFieldDataonBlurEvent();
      }
    }
  }

  const saveFlatNo = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let flat_no = e.target.value;
    if (!flat_no) {
      setFormErrors({flat : "This field is required."});
    }else if (flat_no!=undefined && flat_no.length >10 ) {
      setFormErrors({flat : "Flat no should not greater then 10 character."});
    }else{    
      if(sessionStorage.getItem("lead_id")!=undefined){       
        saveFieldDataonBlurEvent();
      }
    }
  }

  const saveBlockStreet = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let blockStreet = e.target.value;
    if (!blockStreet) {
      setFormErrors({block : "This field is required."});
    }else {
      if(sessionStorage.getItem("lead_id")!=undefined){        
        saveFieldDataonBlurEvent();
      }
    }
  }

  const savelocality = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let locality = e.target.value;
    if (!locality) {
      setFormErrors({locality : "This field is required."});
    }else{
      if(sessionStorage.getItem("lead_id")!=undefined){        
        saveFieldDataonBlurEvent();
     }
    }
  }

  const savePincode = (e) =>{
    e.preventDefault();
    setFormErrors({});
    let pincodeval = e.target.value;
    if (!pincodeval) {
      setFormErrors({pincode : "This field is required."});
    }else if (pincodeval != "" && pincodeval.length < 6) {
      setFormErrors({pincode : "Pincode must be 6 digit."});
    }else{ 
      if(sessionStorage.getItem("lead_id")!=undefined){       
        saveFieldDataonBlurEvent();
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    let errorObj =  validate(data);
      
    if (Object.values(errorObj).length > 0) {
      setFormErrors(errorObj);
    } else {
      // if(data.mobile_no && !otpValidated){
      //   await checkMobileExist(data.mobile_no);
      // }
      setCheckout(true);
      sessionStorage.removeItem("flat_no");
      sessionStorage.removeItem("block_or_street");
      sessionStorage.removeItem("locality");
      sessionStorage.removeItem("city");
      sessionStorage.removeItem("pin_code");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("customer_name");
      sessionStorage.removeItem("stg_mobile_number");

      sessionStorage.setItem("flat_no", data.flat);
      sessionStorage.setItem("block_or_street", data.block);
      sessionStorage.setItem("locality", data.locality);
      sessionStorage.setItem("city", data.city);
      sessionStorage.setItem("state", data.city);
      sessionStorage.setItem("pin_code", data.pincode);
      sessionStorage.setItem("email", data.email);
      sessionStorage.setItem("customer_name", data.first_name);
      sessionStorage.setItem("stg_mobile_number", data.mobile_no);
      sessionStorage.setItem("stg_emailAdress_Checkout_updated", data.email);

      let RandomUuid = genrateNumberUUId();

      moengageCheckoutBtn_Clicked();

      if(sessionStorage.getItem("lead_id")!=undefined){     
        saveFieldDataonBlurEvent();
      }


      // Payment Transaction API //
      UserService.paymentTransaction(
        RandomUuid,
        orderData?.planPrice ?? sessionStorage.getItem("moe_acq_selected_planPrice"),
        data.mobile_no,
        orderData?.planId ?? sessionStorage.getItem("stg_scheme_id"),
        "fresh_" + data.mobile_no,
        gateway,
        data.email,
        "",
        sessionStorage.getItem("lead_id")
      )
        .then((response) => {
          if (response.success) {
            setTransToken(response.txnToken);
            setMid(response.mid);
            sessionStorage.setItem("mId", response.mid);
            sessionStorage.setItem("stg_leadId", response.lead_id);
            sessionStorage.removeItem("stg_mobile_number");
            sessionStorage.removeItem("stg_otpValidated");
            setPaytmPageShow(true);
            setCheckout(false);
          } else {
            setCheckout(false);
            //this.setState({ loading: false });
          }
        })
        .catch((err) => {
          //this.defultAlert("Failed! Check your network")
          //this.setState({ loading: false });
          sessionStorage.removeItem("stg_mobile_number");
          setCheckout(false);
        });
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const validate =  (values) => {
    const errors = {};
    if (!values.mobile_no) {
      errors.mobile_no = "This field is required.";
    }else if(values.mobile_no!='' && values.mobile_no.length < 10){
      errors.mobile_no = "Mobile No. should not less then 10 digit";
    
    }else if(!otpValidated && !mobileError){
      errors.mobile_no = "Mobile number verification is incomplete. Please complete the OTP process.";
      defultAlert("Mobile number verification is incomplete. Please complete the OTP process.");
    }
    if (!values.email) {
      errors.email = "This field is required.";
    } else if (values.email != "" && !emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format.";
    }

    if (!values.first_name) {
      errors.first_name = "This field is required.";
    }
    if (!values.flat) {
      errors.flat = "This field is required.";
    }
    if (values.flat!=undefined && values.flat.length >10 ) {
      errors.flat = "Flat no should not greater then 10 character.";
    }
    if (!values.block) {
      errors.block = "This field is required.";
    }
    if (values.block!=undefined && values.block.length >50) {
      errors.block = "Block/street no should not greater then 50 character.";
    }
    if (!values.locality) {
      errors.locality = "This field is required.";
    }
    if (values.locality!=undefined && values.locality.length >50 ){
      errors.locality = "Locality should not greater then 50 character.";
    }
    if (!values.city) {
      errors.city = "This field is required.";
    }
    if (!values.pincode) {
      errors.pincode = "This field is required.";
    }
    if (values.pincode != "" && values.pincode.length < 6) {
      errors.pincode = "Pincode must be 6 digit.";
    }

    if (!values.terms) {
      errors.termsmsg = "Please accept terms and conditions";
      setTermsErrmsg(true);
    } else {
      setTermsErrmsg(false);
    }
    return errors;
  };

  const redirectToHome = () => {
    if (sessionStorage.getItem("createNewAccountActive") === "ON") {
      moengage_Acq_CheckoutBackBtn_Clicked();      
      sessionStorage.removeItem("stg_mobile_number");
      sessionStorage.removeItem("stg_otpValidated");
      if(forInstitutional){
        window.location = "/?msg=newCustomer&utm_source=Corporate";
      }else{
        window.location = "/newCustomer";
      }
    } else {
      sessionStorage.removeItem("stg_otpValidated");
      window.location = "/";
    }
  };

  const showTermsPopup = () => {
    setShowPopupAlertState(true);
  };

  const closeTermsPopup = () => {
    setShowPopupAlertState(false);
  };

  const onDismissRazorpay =()=> {
    setPaytmPageShow(false);
    setCheckout(false);
   }

   return (
    loading ? ( <PageLoader />
    ) : (
      <div className="container">
        
        <div className="row justify-content-center mt-4">
          
          <div className="col-12 col-md-12 px-4">
          
            <div className="ht_plan_summery" id="hide">
              <div className="plan_header_box">
                <div className="nws_paper_title">
                  <div className="back-btn-pincode">
                    <FontAwesomeIcon icon={faArrowLeft} size="2x" className="faArrowLeft" onClick={redirectToHome} />
                  </div>
                  <div className="feature">
                    <span className="p_title">
                      {/* {planNames[orderData.planName]} */}
                      {planNames[sessionStorage.getItem("stg_selectedPlan_Name")] || sessionStorage.getItem("stg_selectedPlan_Name")}
                    </span>
                    <span className="year_title">
                      {/* {orderData.planDuration > 0 && orderData.planDuration + " Year"} */}
                      {sessionStorage.getItem("moe_acq_selected_yearPlan") > 0 && sessionStorage.getItem("moe_acq_selected_yearPlan") + " months Plan"}
                    </span>
                  </div>
                </div>
                <div className="nws_paper_price_sec">
                  <span className="last_price">
                    <strike>
                      {/* {orderData.coverPrice > 0 && "₹"}
                      {orderData.coverPrice > 0 && orderData.coverPrice} */}
                      {sessionStorage.getItem("moe_acq_selected_coverPrice") > 0 && "₹"}
                      {sessionStorage.getItem("moe_acq_selected_coverPrice") > 0 && sessionStorage.getItem("moe_acq_selected_coverPrice")}
                    </strike>
                  </span>
                  {/* <span className="orgnl_price">₹{orderData.planPrice}</span> */}
                  <span className="orgnl_price">₹{sessionStorage.getItem("moe_acq_selected_planPrice")}</span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="row summery_plan_row">
          <div className="col-12 col-md-12 px-5">
            <div className="contact_plan_summery px-2">
              <div className="header_title">
                <img src={contactIcon} className="mr-3" />
                <span>Contact Information</span>
              </div>
              <div className="contact_header_box">
                <div className="cntct_title">
                  <form className="cntct_fields">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="input-style">
                          <label htmlFor="mobile">
                            Mobile No <span>*</span>
                          </label>
                          <div className="d-flex">
                          <input
                            type="text"
                            className={`order-input ${otpValidated && data.mobile_no ? 'valid-tick' : ''}`}
                            name="mobile_no"
                            value={data.mobile_no || ""}
                            onChange={handleChange}
                            onBlur={checkMobileExistWithEvent}
                            placeholder="Enter Mobile No"
                            id="mobile"
                            autoComplete="off"
                            maxLength={10}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                            }}
                          />
                          {otpValidated && data.mobile_no && (
                            <span className="input-tick">✔</span>
                          )}
                          </div>
                          <span className="text-danger">{mobileError || formErrors.mobile_no}</span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-style">
                          <label htmlFor="email">
                            Email Id <span>*</span>
                          </label>
                          <div className="d-flex">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            className={`order-input ${otpValidatedEmail && data.email && forInstitutional ? 'valid-tick' : ''}`}
                            placeholder="Enter email"
                            value={data.email || ""}
                            readOnly={otpValidatedEmail && data.email && forInstitutional}
                            onChange={!otpValidatedEmail || !forInstitutional ? handleChange : undefined}
                            onBlur={!otpValidatedEmail || !forInstitutional ? saveEmailWithEvent : undefined}
                          />
                          {otpValidatedEmail && data.email && forInstitutional && (
                            <span className="input-tick">✔</span>
                          )}
                          </div>
                          <span className="text-danger">{formErrors.email}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row summery_plan_row">
          <div className="col-md-12 px-5">
            <div className="address_plan_summery px-2">
              <div className="header_title">
                <img src={addressBook} className="mr-3" />
                <span>Shipping Address</span>
              </div>
              <div className="address_header_box">
                <div className="adres_title">
                  <form className="address_fields">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="fname">
                          Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="fname"
                          name="first_name"
                          value={data.first_name || ""}
                          onChange={handleChange}
                          onBlur={saveName}
                          placeholder="Enter Name"                     
                        />
                        <span className="text-danger">
                          
                          {formErrors.first_name}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="flat">
                          Flat/House No/Floor No.
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="flat"
                          name="flat"
                          value={data.flat || ""}
                          maxLength={9}
                          onChange={handleChange}
                          onBlur={saveFlatNo}
                          placeholder="Enter Flat/House No"
                        />
                        <span className="text-danger">{formErrors.flat}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="block">
                          Block/Street/Plot No.
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="block"
                          name="block"
                          maxLength={50}
                          value={data.block || ""}
                          onChange={handleChange}
                          onBlur={saveBlockStreet}
                          placeholder="Enter Block/Street No"
                        />
                        <span className="text-danger">{formErrors.block}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="input-style">
                        <label htmlFor="locality">
                          Locality <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="locality"
                          name="locality"
                          maxLength={50}
                          value={data.locality || ""}
                          onChange={handleChange}
                          onBlur={savelocality}
                          placeholder="Enter Locality"
                        />
                        <span className="text-danger">{formErrors.locality}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="input-style">
                          <label htmlFor="city">
                            City <span>*</span>
                          </label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            readOnly={true}
                            disabled={true}
                            value={data.city || ""}
                            onChange={handleChange}
                          />
                          <span className="text-danger">{formErrors.city}</span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-style">
                          <label htmlFor="pincode">
                            Pincode <span>*</span>
                          </label>
                          {pincodeGiven? 
                            (
                              <input
                              type="text"
                              id={data.pincode}
                              name="preshowpincode"
                              maxLength={6}
                              value={data.pincode}
                              readOnly
                            />
                            ):
                            (
                            <input
                            type="text"
                            id={data.pincode}
                            name="pincode"
                            maxLength={6}
                            value={data.pincode || ""}
                            onChange={handleChange}
                            onBlur={savePincode}
                            placeholder="Enter Pincode"
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                            }}
                          />
                            )
                          
                          }
                        <span className="text-danger">{formErrors.pincode}</span>
                        </div>
                      </div>
                  </div>
                  <div className="terms mb-3 blackClourV2">
                      <input
                        type="checkbox"
                        name="terms"
                        id="terms"
                        value={isChecked}
                        checked={isChecked}
                        onChange={updateTermsCheckbox}
                      />
                      <label for="terms" className="terms-label ml-2">
                        <span>I agree to </span>
                        <a href="javascript: void(0)" onClick={showTermsPopup}> Terms &amp; Conditions</a>
                      </label>
                      {termsErrmsg && (
                        <span className="text-danger">
                          {"Please agree terms & conditions"}
                        </span>
                      )}
                      
                    </div>
                    <div className="address_btn mt-1 mb-3 text-center">
                      {(!checkout ? (<button
                        className="btn checkout-btn"
                        value={data.terms}
                        onClick={handleSubmit}
                      >
                        CHECKOUT
                      </button>) : 
                      (<button
                        className="btn checkout-btn disabled" disabled
                      >
                        Please wait....
                      </button>)
                      
                    )}
                      {/* <button
                        className="btn checkout-btn"
                        value={data.terms}
                        onClick={handleSubmit}
                      >
                        CHECKOUT
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {paytmPageShow &&
          gateway &&
          (gateway === "Paytm" ? (
            <ShowPaytmPaymentPage txnToken={transToken} />
          ) : (
            <ShowRazorPayPaymentPage
              txnToken={transToken}
              mobile={data.mobile_no}
              email={data.email}
              name={data.first_name}
              price={orderData?.planPrice ?? sessionStorage.getItem("moe_acq_selected_planPrice")}
              onDismissRazorpay={onDismissRazorpay}
            />
          ))}
            <TermsPopup
            showModalPopup={showPopupAlertState}
            onPopupClose={closeTermsPopup}
          ></TermsPopup>
          <OTPValidation
              show={showOTPModal}
              handleClose={(success) => {
                  setShowOTPModal(false);
                  setOtpValidated(success);
              }}
              sendOtp={() => sendOtp(data.mobile)}
              validateOtp={validateOtp}
              createNewLead={createNewLead}
              otpSource={data.mobile_no}
              // otpSource={otpType ? data.email : data.mobile_no}
              // otpType={otpType}
          />
          <CorporateMailUpdatePopup show={showMailUpdateModal}
              handleClose={(success) => {
                  setShowMailUpdateModal(false);
                  // setOtpValidated(success);
              }}
              mobile={data.mobile_no}
              email={data.email}
              updateEmailAddress={updateEmailAddress}
              defultAlert={defultAlert}
              emailUpdated={emailUpdated}
              />
          {/* <CorporateDigitalPopup show={validDigitalOrder}
              handleClose={(success) => {
                  setValidDigitalOrder(false);
                  // setOtpValidated(success);
              }}
              mobile={data.mobile_no}
              email={data.email}
              /> */}
          <ToastContainer />
      </div>
    )
  );
}

export default Order;
